.projectOverview{
    background: url(../../assets/img/borad-bg4.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 280px;
    display: flex;
    flex-direction: column;
}
.content{
    flex: 1;
    width: 100%;
    padding: 0 10px 16px 16px;
    margin-top: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}
.scrollBox{
    width: 100%;
    padding-right: 6px;
    box-sizing: border-box;
    position: relative;
}
.content::after{
    content: '';
    background: url(../../assets/img/shine-line@2x.png) no-repeat;
    width: 100%;
    height: 1px;
    background-size: cover;
    position: absolute;
    bottom: 16px;
    left: 0;
}
.item{
    width: 100%;
    display: flex;
    align-items: center;
    height: 36px;
    color: var(--textColor);
    cursor: pointer;
}
.head{
    color: var(--blueColor);
    height: 36px;
    background: url(../../assets/img/Table-bg@2x.png) no-repeat;
    width: 100%;
    background-size: cover;
}
.citem{
    height: 36px;
    line-height: 36px;
    /* font-size:14px; */
}
.citem{
    padding-left: 10px;
    box-sizing: border-box;
}
.citem:nth-child(1){
    width: 15%;
    
}
.citem:nth-child(2){
    width: 15%;
}
.citem:nth-child(3){
    width: 57%;
    
}
.citem:nth-child(4){
    width: 13%;
}
