.header{
    background: url(../../assets/img/head_bg.png) no-repeat;
    background-size: cover;
    width: 1920px;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.title{
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #E0F7FF;
    line-height: 32px;
    letter-spacing: 8px;
    text-shadow: 0px 2px 20px rgba(0,153,255,0.45), 0px 2px 2px rgba(14,43,71,0.65);
    margin-top: -10px;
}

.title img{
    width: 550px;
    /* height: 68px; */
}

.fullScreen{
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
.split{
    width: 1px;
    height: 48px;
    margin-top: 8px;
}
.full{
    width: 20px;
    height: 20px;
    margin-left: 18px;
    cursor: pointer;
}
.logoicon{
    width: 138px;
    position: absolute;
    top: 35px;
    transform: translateY(-50%);
    left: 30px;
}