.modalBox{
    width: 958px;
    background: rgba(0,170,255,0.15);
    padding-bottom: 20px;
    color: white;
    z-index: 999;
}
.header{
    width: 100%;
    height: 64px;
    background: RGBA(11, 60, 98, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
}
.modalTitle{
    font-size: 18px;
}
.closeBox{
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid rgba(0,170,255,0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    background: RGBA(12, 43, 70, 1);
    cursor: pointer;
}
.closeBox img{
    width: 16px;
    height: 16px;
}
.content{
    padding:24px 10px 24px 48px;
    box-sizing: border-box;
    overflow: hidden;
    flex: 1;
}
.scrollBox{
    padding-right: 38px;
    box-sizing: border-box;
    user-select:text;
}

/* 预警信息 */
.yj_title{
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #E0F7FF;
    line-height: 32px;
    text-align: center;
}
.yj_line{
    background:RGBA(33, 107, 145, 1);
    height: 1px;
    width: 100%;
    margin: 24px 0 19px 0;
}
.auther{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5CDEFF;
    line-height: 14px;
}
.articleBox{
    margin-top: 24px;
}
.articleBox p{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #E0F7FF;
    line-height: 40px;
}
.articleBox img{
    width: 100%;
    margin: 16px 0;
}

/* 专家连线 */
.expertListBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.zj_item{
    background: url(../../assets/img/exp.png) no-repeat;
    background-size: cover;
    width: 420px;
    height: 192px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 24px;
}
.zj_head{
    width: 128px;
    height: 160px;
    margin-right: 16px;
}
.infoBox{
    flex: 1;
    position: relative;
    
}
.zj_name{
    display: flex;
    align-items: center;
}
.zj_name_span{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: var(--textColor);
    line-height: 16px;
    text-shadow: 0px 2px 1px rgba(0,0,0,0.45);
}
.zj_name_i{
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: var(--textColor);
    line-height: 12px;
    background: RGBA(23, 194, 137, 1);
    padding: 10px 20px;
    border-radius: 10px;
    margin-left: 0px;
}
.zi_title{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--blueColor);
    line-height: 14px;
    text-shadow: 0px 2px 1px rgba(0,0,0,0.45);
    width: 100px;
    display: inline-block;
    text-align: justify;
    text-align-last: justify;
    flex-shrink: 0;
}
.zj_value{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--textColor);
    line-height: 14px;
    text-shadow: 0px 2px 1px rgba(0,0,0,0.45);
}
.zj_info{
    margin-top: 12px;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
	overflow: hidden;
    color:#FFFFFF;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
    line-clamp: 2;
	-webkit-box-orient: vertical;
}
.zj_infoarea{
    display: flex;
    align-items: center;
}
.zj_buttonBox{
    display: flex;
    justify-content:flex-end;
}
.zj_lxzj{
    background: url(../../assets/img/lxzj.png) no-repeat;
    background-size: contain;
    width: 88px;
    height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--textColor);
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 24px;
    top: -218px;
    bottom: -6px;
    cursor: pointer;
}

/* 专家问诊 */
.expertInterrogationBox{

}
.question{

}
.answer{
    margin-top: 30px;
}
.dashedBox{
    display: flex;
    align-items: center;
}
.dashedTit{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: var(--textColor);
    line-height: 16px;
}
.dashed{
    height: 1px;
    flex: 1;
    border-bottom: 0.5px dashed var(--blueColor);
    margin-left: 20px;
}
.itemkeyVal{
    display: flex;
    flex-wrap: wrap;
}
.itemkeyValItem{
    width: 50%;
    margin-top: 22px;
    display: flex;
}
.itemkeyValItem100{
    width: 100%;
}
.itemkeyValItem .zi_title{
    line-height: 22px;
}
.itemkeyValItem .zj_value{
    margin-left: 28px;
    line-height: 22px;
}
.starClass{
    width: 20px;
    height: 20px;
    margin-right: 20px;
}











/* 项目一览 */
.projectListImg{
    width: 100%;
}
.projecrDesc{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--textColor);
    line-height: 22px;
    margin-top: 21px;
}
.projecrDescYx{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.xm_img{
    width: 400px;
    height: 200px;
    margin-bottom: 24px;
}