.center-animate {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &.show {
    display: block;
  }
  &.none {
    display: none;
  }

  &__container {
    width: 880px;
    height: 550px;
    background: url(../../assets/img/d1.png) no-repeat;
    background-size: cover;
    position: relative;
    left: 30px;
  }

  &__doll {
    position: absolute;
    left: 51%;
    top: 110px;
    width: 200px;
    transform: translateX(-50%);
    margin-left: -10px;
  }

  &__list {
    position: relative;
    &:hover {
      .center-animate__item {
        animation-play-state: paused;
      }
    }
  }

  &__item {
    width: 251px;
    height: 157px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: rotate3d(0, 0, 0);
    cursor: pointer;
    background: url(../../assets/img/d2.png) no-repeat;
    background-size: cover;
    &:hover {
      .center-animate__item__img {
        display: none;
      }
      .center-animate__item__imgHover {
        display: block;
      }
    }
    img {
      width: 100px;
      height: 100px;
      margin-top: -110px;
    }
    span {
      font-family: PangMenZhengDao, PangMenZhengDao;
      font-weight: normal;
      font-size: 33px;
      color: #ffffff;
      line-height: 38px;
      letter-spacing: 3px;
      text-shadow: 0px 6px 10px #004752, 2px -2px 0px #adffad,
        2px -1px 0px #007c8f;
      text-align: center;
      font-style: normal;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__imgHover {
      display: none;
    }
    &:nth-child(1) {
      animation: anmiteX 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -5s infinite alternate,
        anmiteY 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) 0s infinite alternate,
        scale 20s cubic-bezier(0.6, 0.3, 0.3, 0.6) 0s infinite alternate;
    }
    &:nth-child(2) {
      animation: anmiteX 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -8.5s infinite alternate,
        anmiteY 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -3.5s infinite alternate,
        scale 20s cubic-bezier(0.6, 0.3, 0.3, 0.6) -3.5s infinite alternate;
    }
    &:nth-child(3) {
      animation: anmiteX 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -12s infinite alternate,
        anmiteY 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -7s infinite alternate,
        scale 20s cubic-bezier(0.6, 0.3, 0.3, 0.6) -7s infinite alternate;
    }
    &:nth-child(4) {
      animation: anmiteX 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -15.5s infinite alternate,
        anmiteY 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -10.5s infinite alternate,
        scale 20s cubic-bezier(0.6, 0.3, 0.3, 0.6) -10.5s infinite alternate;
    }
    &:nth-child(5) {
      animation: anmiteX 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -19s infinite alternate,
        anmiteY 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -14s infinite alternate,
        scale 20s cubic-bezier(0.6, 0.3, 0.3, 0.6) -14s infinite alternate;
    }
    &:nth-child(6) {
      animation: anmiteX 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -22.5s infinite alternate,
        anmiteY 10s cubic-bezier(0.6, 0.3, 0.3, 0.6) -17.5s infinite alternate,
        scale 20s cubic-bezier(0.6, 0.3, 0.3, 0.6) -17.5s infinite alternate;
    }
  }
}

/* 在X轴上的移动 */
@keyframes anmiteX {
  from {
    left: 700px;
  }
  to {
    left: -100px;
  }
}
/* 在轴上Y的移动 */
@keyframes anmiteY {
  from {
    top: 260px;
  }
  to {
    top: 440px;
  }
}
/* 对球进行放大和缩小在视觉上进行远近的模拟 */
@keyframes scale {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  // 30% {
  //   transform: scale(0.5, 0.5);
  //   opacity: 1;
  // }
  50% {
    transform: scale(1, 1);
    opacity: 1;
  }
  // 70% {
  //   transform: scale(0.5, 0.5);
  //   opacity: 1;
  // }
  100% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
}
