.title{
    font-size: 16px;
    line-height: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
}
.item{
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-top: 3px;
}
.title .item i{
    background: url(../../assets/img/title.png) no-repeat;
    background-size: cover;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    margin-left: 16px;
}
.title .item p a{
    font-weight: 500;
    /* color: rgba(194, 247, 255,1); */
    color: rgba(255, 255, 255,1);
    text-decoration: none;
}
.title .hoverStyle p a:hover{
    color: rgb(33, 212, 240);
}
.title .item p a:hover{
    color: rgb(33, 212, 240);
}
.title .item p a[href=""]:hover, .title .item p a:not([href]):hover {
    color: rgba(194, 247, 255,1);
}

.title .item p span{
    font-weight: 400;
    color: #00B3E0;
}
.select{
    width: 120px;
    height: 28px;
    background: rgba(10,32,51,0.65);
    border-radius: 4px;
    border: 1px solid rgba(0,170,255,0.25);
    color: #475669;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    margin-top: 4px;
    margin-right: 16px;
    margin-top: 14px;
}
.marginTop10{
    margin-top: 10px;
}

