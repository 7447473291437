.projectOverview{
  background: url(../../assets/img/borad-bg2_2.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 520px;
  margin-top: 13px;
  display: flex;
  flex-direction: column;
}
.content{
  flex: 1;
  padding: 0 10px 16px 16px;
  margin-top: 18px;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}
.styleBox{
  width:460px;
  height:520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
}
.barChart{
  width: 100%;
  height: 100%;
}
.main{
  width:212px;
  height:212px;
  background: url(../../assets/img/Chart-bg.svg) no-repeat;
  background-size: cover;
}
.item{
  width: 346px;
  height: 32px;
  background: linear-gradient(270deg, RGBA(14, 44, 82, 1) 0%,RGBA(13, 62, 107, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 16px;
}
.tit{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: RGBA(56, 185, 233, 1);
  display: flex;
  align-items: center;
}
.tit i{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(2, 167, 250, 1);
  margin-right: 6px;
}
.cont{
  display: flex;
  align-items: center;
}
.total{
  font-size: 16px;
  font-weight: 400;
  color: RGBA(194, 247, 255, 1);
}
.total span{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.split{
  width: 1px;
  height: 8px;
  background: RGBA(194, 247, 255, 1);
  margin: 0 12px;
}