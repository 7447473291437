body {
  background: url(../../assets/img/bgLogin.png) no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
}

input {
  outline: none;
  padding: 0 15px;
  font-size: 16px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #5096ff !important;
  }
}
input::placeholder {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a1abbd;
}

.login {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__container {
    width: 548px;
    margin-top: -80px;
    .login__title {
      width: 100%;
      font-size: 40px;
      font-family: AlibabaPuHuiTiB;
      color: #ffffff;
      line-height: 40px;
      letter-spacing: 8px;
      text-shadow: 0px 2px 8px #0a2044;
      text-align: center;
    }
    .login__content {
      width: 100%;
      height: 460px;
      background: rgba(255, 255, 255, 0.91);
      box-shadow: 0px 24px 40px 0px rgba(0, 57, 131, 0.3),
        0px 6px 12px 0px rgba(32, 118, 213, 0.15);
      border-radius: 24px;
      border: 1px solid #ffffff;
      backdrop-filter: blur(10px);
      margin-top: 40px;
      padding: 48px 40px 56px;
      box-sizing: border-box;
      .login__label {
        font-size: 28px;
        color: #2a3a5e;
        font-weight: 600;
      }
      .login__wirteContent {
        margin-top: 48px;
        width: 100%;
      }
      .login__item {
        position: relative;

        &:not(:first-child) {
          margin-top: 24px;
        }
        .login__write {
          width: 100%;
          height: 48px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(14, 134, 207, 0.09),
            0px -1px 2px 0px rgba(255, 255, 255, 0.25),
            inset 0px 0px 6px 0px rgba(10, 118, 190, 0.19);
          border-radius: 12px;
          border: 1px solid #c5d0d6;
          overflow: hidden;
          box-sizing: border-box;
        }
        .login__checkCode {
          width: 116px;
          height: 100%;
          background: #f5f6f7;
          border-radius: 0px 30px 30px 0px;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          img {
            border-radius: 0px 30px 30px 0px;
            width: 100%;
            height: 100%;
          }
        }
        button {
          width: 100%;
          height: 48px;
          background: linear-gradient(143deg, #4f95ff 0%, #5acffc 100%);
          box-shadow: 0px 4px 12px 0px rgba(14, 152, 231, 0.43),
            0px -2px 2px 0px rgba(255, 255, 255, 0.25);
          border-radius: 12px;
          border: 1px solid #ffffff;
          outline: none;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            box-shadow: 0px 4px 4px 0px rgba(14, 152, 231, 0.43),
              0px -2px 2px 0px rgba(255, 255, 255, 0.25);
          }
          &:active {
            box-shadow: 0px 0px 0px 0px rgba(14, 152, 231, 0.43),
              0px -2px 2px 0px rgba(255, 255, 255, 0.25);
          }
        }
      }
    }
  }
}
