* {
  margin: 0;
  padding: 0;
  user-select: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*公共字体的样式 */
@font-face {
  font-family: 'BebasNeue';
  src: url('@/assets/font/BebasNeue-Regular-2.otf') format('woff'),
    /* Modern Browsers */ url('@/assets/font/BebasNeue-Regular-3.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'PingFang SC';
  src: url('@/assets/font/PingFangSC.ttf') format('truetype'); /* Safari, Android, iOS */
}
:root {
  --textColor: rgba(197, 228, 255, 1);
  --blueColor: #5cdeff;
}

.eli {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.eli1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.eli2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.eli3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.eli4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.eli5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.scroll {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.scroll::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
.scroll::-webkit-scrollbar-track {
  background: RGBA(2, 60, 95, 0.2);
  border-radius: 2px;
}
.scroll::-webkit-scrollbar-thumb {
  background: RGBA(2, 60, 95, 1);
  border-radius: 2px;
}
.scroll::-webkit-scrollbar-thumb:hover {
  background: RGBA(2, 60, 95, 1);
  border-radius: 2px;
}
.scroll::-webkit-scrollbar-corner {
  background: RGBA(2, 60, 95, 1);
  border-radius: 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.arrow {
  width: 10px;
  height: 10px;
}

.ant-select-selector {
  height: 100% !important;
  width: 100% !important;
  line-height: initial !important;
}
.ant-select-selection-search-input {
  height: 100% !important;
}
.ant-select-selection-item {
  line-height: 26px !important;
}
.ant-select-selector {
  background: transparent !important;
  border: none !important;
  color: var(--textColor) !important;
  font-size: 12px !important;
}
.ant-list .ant-list-item {
  padding: 0;
}
.spinCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textColor);
}
.typicalCases .slick-active button {
  background: rgba(92, 222, 255, 1) !important;
}
.projectList .ant-list .ant-list-item:nth-child(2n) {
  background: rgba(0, 170, 255, 0.09) !important;
}

.baseClass .ant-modal {
  width: 958px !important;
  height: 100%;
  max-height: 936px !important;
}
.baseClass .ant-modal-content {
  background: RGBA(14, 41, 71, 1);
  padding: 0;
  width: 100%;
  height: 100%;
  background: #0e2947;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.65);
  border: 1px solid rgba(0, 170, 255, 0.45);
  border-radius: 0;
}
.baseClass .ant-modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.baseClass .ant-modal-header {
  height: 64px;
  background: rgba(0, 170, 255, 0.15);
  margin-bottom: 0;
}
.baseClass .ant-modal-footer {
  display: none;
}
.baseClass .titleBox {
  font-size: 18px;
}
.mapboxCon .ant-picker-input {
  width: 80px !important;
}
.mapboxCon .ant-picker-range {
  padding-right: 12px;
}
.mapboxCon .ant-picker-suffix {
  margin-left: 10px;
}
.mapboxCon .ant-select-selection-item {
  line-height: 34px !important;
}
.mapboxCon .ant-picker-input input {
  line-height: 25px !important;
}

/* #tooltip .head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  background: linear-gradient(180deg, rgba(0,229,255,0.15) 0%, rgba(0,229,255,0.02) 100%);
  padding: 0 8px 0 12px;
  box-sizing: border-box;
  color: var(--textColor);
}
#tooltip .check{
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5CDEFF;
  display: flex;
  align-items: center;
}
#tooltip .check .close{
  background: url(./assets/img/close2.png) no-repeat;
  background-size: cover;
  width: 12px;
  height: 12px;
}
#tooltip .check .split{
  width: 1px;
  height: 12px;
  background: RGBA(7, 64, 93, 1);
  margin: 0 8px;
}
#tooltip .tcon{
  box-sizing: border-box;
  padding:6px 20px;
}
#tooltip .tcon .item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
}
#tooltip .tcon .item .tit{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: var(--blueColor);
  line-height: 12px;
  text-shadow: 0px 3px 8px rgba(0,0,0,0.4);
}
#tooltip .tcon .item .num{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: var(--textColor);
  line-height: 12px;
  text-shadow: 0px 3px 8px rgba(0,0,0,0.4);
} */
