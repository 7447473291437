.App {
  background: url(@/assets/img/bg.png) no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
}
.content {
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
}
.left {
  width: 460px;
}
.center {
  width: 936px;
  display: flex;
  flex-direction: column;
}
.right {
  width: 460px;
}
#tooltip {
  position: relative;
  box-sizing: border-box;
  box-shadow: initial;
  margin-left: -20px;
  padding: 10px;
  border: 1px solid rgb(132, 204, 155);
}
.tooltip1 {
  width: 196px;
  height: 114px;
  background: url(../../assets/img/tip.png) no-repeat;
  background-size: cover;
}

.tooltip2 {
  width: 184px;
  height: 198px;
  background: url(../../assets/img/tip2.png) no-repeat;
  background-size: cover;
}

.tooltip3{
  width: 184px;
  height: 248px;
  background: url(../../assets/img/tip3.png) no-repeat;
  background-size: cover;
}


