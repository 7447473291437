.projectOverview{
    background: url(../../assets/img/borad-bg1_2.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 440px;
    display: flex;
    flex-direction: column;
}
.content{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    flex-wrap: wrap;
}
.item{
    width: 137px;
    height: 176px;
    background: url(../../assets/img/xbg.png) no-repeat;
    background-size: cover;
    padding: 8px;
    box-sizing: border-box;
}
.tit{
    /* font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500; */
    color: #5CDEFF;
    line-height: 14px;

    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 12px 0;
}
@media (max-width: 1500px) {
    .tit{
        font-size: 10px;
    }
}
.dash{
    height: 1px;
    width: 80%;
    border-bottom: 1px dashed rgba(0,170,255,0.25);
    margin: 0 auto;
}
.xImgBox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
}
.xImg{
    width: 57px;
    height: 57px;
}
.num{
    font-size: 24px;
    font-family: BebasNeue-Regular, BebasNeue;
    font-weight: 700;
    color: #ffffff;
    line-height: 24px;
    margin-top: 10px;
    display: flex;
    align-items: baseline;
    justify-content: center;

    /* font-size: 32px; */
    font-family: BebasNeue-Regular, BebasNeue;
    font-weight: 400;
    /* color: #63C0F8; */
    /* line-height: 28px; */
    /* text-shadow: 0px 1px 5px rgba(0,0,0,0.65); */
    background: linear-gradient(180deg, #87D6F1 0%, #63C0F8 20%,#ffffff 21%, #ffffff 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.num span{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    margin-left: 3px;
}
.numScroll{
    text-align: center;
    margin-top: -6px;
}