.projectOverview{
    background: url(../../assets/img/borad-bg3.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 312px;
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}
.content{
    flex: 1;
    padding: 0 10px 16px 16px;
    margin-top: 18px;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
}
.scrollBox{
    width: 100%;
    padding-right: 6px;
    box-sizing: border-box;
}
.item{
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0 12px;
    background: rgba(0,170,255,0.09);
    margin-bottom: 8px;
    cursor: pointer;
}
.item i{
    background: url(../../assets/img/Shape@2x.png) no-repeat;
    background-size: cover;
    width: 6px;
    height: 6px;
    margin-right: 8px;
}
.item p{
    /* font-size: 14px; */
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--textColor);
    line-height: 14px;
    text-shadow: 0px 2px 1px rgba(0,0,0,0.45);
    width: 301px;
}
.item span{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--blueColor);
    line-height: 12px;
    margin-left: 10px;
    flex-shrink: 0;
}

