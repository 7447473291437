.projectOverview{
    background: url(../../assets/img/borad-bg3.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 312px;
    display: flex;
    flex-direction: column;
}
.content{
    flex: 1;
    /* margin-top: 10px; */
    box-sizing: border-box;
    overflow: hidden;
    padding: 16px 16px 16px 16px;
    position: relative;
}
.img{
    height: 240px;
    width: 100%;
    object-fit: cover;
}
.typicalDots{
    justify-content: flex-end !important;
    padding-right: 16px;
    bottom: 0px !important;
    height: 40px;
    width: 100%;
/*     background: rgba(10,32,51,0.45); */
    box-sizing: border-box;
    align-items: center;
}
.typicalDots li{
    width: 8px !important;
    height: 8px !important;
}
.typicalDots li button{
    height: 8px !important;
    border-radius: 50% !important;
    /* background: rgba(92, 222, 255, 0.25) !important; */
}
.parent{
    position: relative;
    cursor: pointer;
}
.titleBox{
    height: 40px;
    width: 100%;
    background: rgba(10,32,51,0.45);
    position: absolute;
    bottom: 0;
}
.title{
    position: absolute;
    left: 16px;
    bottom: 13px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: white;
    line-height: 14px;
    text-shadow: 0px 2px 1px rgba(0,0,0,0.45);
}
