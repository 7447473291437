.projectOverview{
    background: url(../../assets/img/borad-bg6.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 80px;
    margin-top: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.projectOverview img{
    width: 448px;
    height: 68px;
    cursor: pointer;
}