.projectOverview{
    background: url(../../assets/img/borad-bg5_1.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 320px;
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}
.content{
    flex: 1;
    padding: 0 10px 16px 16px;
    margin-top: 10px;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
}
.scrollBox{
    width: 100%;
    padding-right: 6px;
    box-sizing: border-box;
}
.item{
    width: 100%;
    height: 64px;
    background: rgba(0,170,255,0.09);
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 8px;
    cursor: pointer;
}
.dealNo{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.date{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--blueColor);
    line-height: 12px;
}
.title{
    display: flex;
    align-items: center;
}
.title i{
    background: url(../../assets/img/Shape@2x.png) no-repeat;
    background-size: cover;
    width: 6px;
    height: 6px;
    margin-right: 8px;
}
.title p{
    /* font-size: 14px; */
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--textColor);
    line-height: 14px;
    text-shadow: 0px 2px 1px rgba(0,0,0,0.45);
}
.theme{
    /* font-size: 14px; */
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: var(--textColor);
    line-height: 14px;
    text-shadow: 0px 2px 1px rgba(0,0,0,0.45);
    margin: 12px 0 0 14px;
}

/* new */
.insideBox{
    background: url(../../assets/img/borad-bg5_bg.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 8px 16px 0px 16px;
}
.row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rowItem{
    background: url(../../assets/img/borad-bg5_i1.png) no-repeat;
    background-size: cover;
    width: 120px;
    height: 96px;
    text-align: center;
    cursor: pointer;
}
.rowItem:nth-child(2){
    background: url(../../assets/img/borad-bg5_i2.png) no-repeat;
    background-size: cover;
}
.row:nth-child(2) .rowItem:nth-child(1){
    background: url(../../assets/img/borad-bg5_i3.png) no-repeat;
    background-size: cover;
}
.row:nth-child(2) .rowItem:nth-child(2){
    background: url(../../assets/img/borad-bg5_i4.png) no-repeat;
    background-size: cover;
}
.rowNum{
    font-size: 28px;
    font-family: BebasNeue-Regular, BebasNeue;
    font-weight: 400;
    color: var(--textColor);
    margin-top: 15px;
    /* font-size: 32px; */
    /* color: #63C0F8; */
    /* line-height: 28px; */
    /* text-shadow: 0px 1px 5px rgba(0,0,0,0.65); */
    background: linear-gradient(180deg, #87D6F1 0%, #63C0F8 20%,#ffffff 21%, #ffffff 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.rowNum span{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #475669;
}
.rowInfo{
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: var(--blueColor);
}