.projectOverview{
    background: url(../../assets/img/borad-bg3.png) no-repeat;
    background-size: cover;
    width: 100%;
    height: 312px;
    margin-top: 13px;
    display: flex;
    flex-direction: column;
}
.content{
    flex: 1;
    box-sizing: border-box;
    padding: 16px;
    overflow: hidden;
}
.video{
    width: 100%;
    height: 100%;
}