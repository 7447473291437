.mapBox {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.operations {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dateBox {
  display: inline-flex;
  align-items: center;
  border: 1px solid rgba(0, 170, 255, 0.25);
  border-radius: 4px;
  padding-left: 10px;
  box-sizing: border-box;
  margin-right: 8px;
  transition: all 0.3s;
}
.datepickerBox {
  width: 231px;
  height: 35px;
}
.dateBox:hover {
  border-color: rgba(0, 170, 255, 1);
}
.dateBox span {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: var(--blueColor);
  line-height: 12px;
}
.dateBox i {
  width: 0.5px;
  height: 16px;
  background: RGBA(5, 63, 97, 1);
  margin-left: 12px;
}
.datePicker {
  /* border-color: rgba(0,170,255,0.25); */
  background: rgba(10, 32, 51, 0.65);
  border-radius: 4px;
  color: var(--textColor);
}
.datePicker input {
  color: var(--textColor) !important;
}
.datePicker input::-webkit-input-placeholder {
  color: rgba(10, 32, 51, 0.65) !important;
}

.toggle {
  background: url(../../assets/img/d_btn.png) no-repeat;
  background-size: cover;
  width: 104px;
  height: 32px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  line-height: 18px;
  text-shadow: 0px 2px 0px #00082e, 0px 1px 0px #003d85;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.refresh {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid rgba(0, 170, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  background: RGBA(12, 43, 70, 1);
  cursor: pointer;
}
.refresh img {
  width: 16px;
  height: 16px;
}
.select {
  min-width: 100px;
}
/* .mapLegend{
    width: 88px;
    height: 154px;
    border-radius: 6px;
    border: 1px solid rgba(0,170,255,0.15);
    box-sizing: border-box;
    padding: 12px 8px;
    text-align: center;
    position:absolute;
    right: 28px;
    bottom: 28px;
    display: none;
} */
.mapLegend {
  width: 88px;
  height: 154px;
  border-radius: 6px;
  border: 1px solid rgba(0, 170, 255, 0.15);
  position: absolute;
  right: 28px;
  bottom: 28px;
}
.mapLegend img {
  width: 88px;
  height: 154px;
}
.mlTitle {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5cdeff;
  line-height: 12px;
}
.mlDesc {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1a9ed6;
  line-height: 12px;
  margin-top: 8px;
}
.tl {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #38bbeb;
  line-height: 12px;
  margin-top: 14px;
}
.tl i {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 3px;
}
.tl1 i {
  background: #4bb352;
}
.tl2 i {
  background: #fabf1e;
}

.fruitBox {
  position: absolute;
  top: 330px;
  left: 90px;
}
.RiceBox {
  top: 230px;
  left: 160px;
}
.fruitBox2 {
  top: 130px;
  left: 200px;
}
.fruitBox3 {
  top: 50px;
  left: 250px;
}

.fruitBox4 {
  top: 170px;
  left: 90px;
}

.fruit {
  position: relative;
  width: 146px;
}
.fruit img {
  width: 88px;
  height: 88px;
}
.fruit .line1 {
  width: 46px;
  height: 1px;
  background: rgba(0, 204, 255, 1);
  position: absolute;
  right: 0;
  top: 44px;
}
.fruit .line1::before {
  content: '';
  width: 12px;
  height: 13px;
  background: url(./../../assets/img/p1.png) no-repeat;
  background-size: cover;
  position: absolute;
  top: -7px;
  left: -17px;
}
.fruit .line2 {
  width: 1px;
  height: 82px;
  position: absolute;
  right: 0;
  top: 44px;
  background: rgba(0, 204, 255, 1);
}
.fruit .line2::after {
  content: '';
  width: 12px;
  height: 13px;
  background: url(./../../assets/img/p2.png) no-repeat;
  background-size: cover;
  position: absolute;
  bottom: -7px;
  left: -6px;
}
.RiceBox .fruit {
  width: 240px;
}
.RiceBox .line1 {
  width: 140px;
}
.RiceBox .line2 {
  height: 150px;
}
.fruitBox2 .fruit {
  width: 280px;
}
.fruitBox2 .line1 {
  width: 180px;
}
.fruitBox2 .line2 {
  height: 120px;
}

.fruitBox3 .fruit {
  width: 230px;
}
.fruitBox3 .line1 {
  width: 130px;
}
.fruitBox3 .line2 {
  height: 200px;
}

.fruitBox4 .fruit {
  width: 340px;
}
.fruitBox4 .line1 {
  width: 240px;
}
.fruitBox4 .line2 {
  height: 120px;
}

.xzftBox {
  display: none;
}
.chartContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
}
.flex {
  display: flex;
}
.none {
  display: none;
}
